body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  margin-top: 0rem;
  padding: 0rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.quote {
  position: relative;
  letter-spacing: 0.03em;
  margin-bottom: 0.5rem;

  &:before {
    content: '“';
    position: absolute;
    left: -0.7em;
  }

  &:after {
    content: '”';
    margin-right: -1rem;
  }
}

.quote--container {
  margin: 4.5rem auto 0;
  width: 17em;
  border-bottom: 2px dotted #c6d1bf;
  padding-bottom: 0.5rem;
}

.quote--highlight {
  color: #d24335;
}

.quote--author {
  font-family: 'Open Sans Condensed';
  font-size: 0.8rem;
  text-align: right;
  font-weight: 300;
}

.icons-bar {
  color: black;
  padding: 20px;
}
